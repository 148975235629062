<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row mb-3">
              <div class="col-6">
                <h4>
                  <router-link :to="{name:'testimonial'}">
                    <i class="fas fa-arrow-left"></i>
                  </router-link>
                  {{ `${id ? 'Update' : "Add"} Testimonial` }}
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                Testimonials
              </div>
              <!--                            <div class="col-2 ">-->
              <!--                                <router-link :to="{name:'testimonial'}" class="btn btn-primary">-->
              <!--                                    <i class="fas fa-arrow-left"></i>-->
              <!--                                    Back-->
              <!--                                </router-link>-->
              <!--                            </div>-->
              <!--                            <div class="col-4">-->
              <!--                                <h4>Add Slider Image</h4>-->
              <!--                            </div>-->
              <div class="col-6 text-right"></div>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 form-group mb-2">
                <v-text-field outlined dense label="Title" v-model="testimonial.title"></v-text-field>
                <span class="text-danger" v-if="$v.testimonial.title.$error">Title is required</span>
              </div>

              <div class="col-12 form-group mb-2">
                <ckeditor
                    :config="editorConfig"
                    v-model="testimonial.description"
                >
                </ckeditor>
              </div>
              <div class="col-md-12 col-sm-12 form-group mb-2">
                <v-file-input accept="image/*"
                              outlined dense
                              :rules="rules"
                              @change="changeImage" v-model="testimonial.imageFile"
                              label="Image"></v-file-input>
                <span class="text-danger" v-if="$v.testimonial.imageFile.$error">Image is required</span>
                <span class="text-danger" v-if="sizeExceed">File Size 20MB Max Allowed</span>
              </div>
              <div class="col-md-3 col-lg-3 col-sm-12 form-group mb-2">
                <v-text-field outlined dense label="Type" v-model="testimonial.type"></v-text-field>
              </div>
              <div class="col-md-3 col-lg-3 col-sm-12 form-group mb-2">
                <v-text-field outlined dense label="Name" v-model="testimonial.name"></v-text-field>
              </div>
              <div class="col-md-3 col-lg-3 col-sm-12 form-group mb-2">
                <v-text-field outlined dense label="Job Title"
                              v-model="testimonial.job_title"></v-text-field>
              </div>

              <div class="col-md-3 col-lg-3 col-sm-12 form-group mb-2">
                <b-form-rating v-model="testimonial.rating" show-value precision="2"></b-form-rating>
              </div>
              <div class="col-md-12 col-lg-2 col-sm-6 form-group mb-2">
                <v-text-field outlined dense label="Position" type="number"
                              v-model="testimonial.position"></v-text-field>
              </div>


              <div class="col-md-12 col-sm-6 form-group mb-2">
                <v-switch  v-model="testimonial.is_active" label="Status">

                </v-switch>

                <!-- <span class="text-danger" v-if="$v.testimonial.is_active.$error">Title is required</span> -->
              </div>
              <div class="col-12">
                <img
                    v-if="testimonial.image_path"
                    :src="testimonial.image_path['thumb']"
                    alt
                    style="height:75px"
                    class="img-thumbnail"
                />
              </div>

              <div class="col-12 mt-5">
                <div class="float-right">
                  <v-btn
                      class="btn btn-standard text-gray"
                      depressed
                      @click="redirectTo"
                  >Cancel
                  </v-btn>
                  <v-btn
                      class="text-white btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                      depressed
                      :disabled="sizeExceed"
                      @click="createOrUpdate"
                      :loading="isBusy"
                      v-if="checkIsAccessible('testimonial', 'create') || checkIsAccessible('testimonial', 'edit')"
                  >Save
                  </v-btn>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import TestimonialService from "@/services/cms/testimonial/TestimonialService";

import {required, requiredIf} from "vuelidate/lib/validators";

const testimonial = new TestimonialService();

export default {
  name: "testimonial-new",
  validations: {
    testimonial: {
      title: {required},
      imageFile: {required:requiredIf(function () {
        return !this.id;
      })
      }
    }
  },
  data() {
    return {
      isBusy: false,
      sizeExceed: false,
      value: 3.5,
      testimonial: {
        id: null,
        title: null,
        link: null,
        imageFile: null,
        position: null,
        new_tab: 0,
        description: null,
        type: null,
        is_active: true
      },
      rules: [
        value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
      },
    };
  },
  mounted() {
    this.id ? this.getTestimonial(this.id) : "";
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    }
  },
  methods: {
    getTestimonial(id) {
      testimonial.show(id).then(response => {
        this.testimonial = response.data.testimonial;
        this.testimonial.is_active ? (this.testimonial.is_active = true) : false;
        this.testimonial.new_tab ? (this.testimonial.new_tab = true) : false;
        this.edit = true;
      });
    },
    changeImage() {
      this.testimonial.image_path = {
        thumb: URL.createObjectURL(this.testimonial.imageFile)
      }
    },
    redirectTo() {
      this.$router.push({name: "testimonial"});
    },
    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      this.sizeExceed = mbSize > 20;
    },
    saveTestimonial(fd) {
      this.isBusy = true;
      testimonial
              .store(fd)
              .then(response => {
                this.isBusy = false;
                this.$snotify.success("Created successfully");
                this.$tabs.close();
                this.$tabs.open({name: "testimonial"});
              })
              .catch(() => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
              });
    },

    createOrUpdate() {
      this.$v.testimonial.$touch();
      if (this.$v.testimonial.$error) {
        setTimeout(() => {
          this.$v.testimonial.$reset();
        }, 3000);
      } else {
        let fd = this.generateFd();
        if (this.edit) {
          this.updateTestimonial(fd);
        } else {
          this.saveTestimonial(fd);
        }
      }
    },
    updateTestimonial(fd) {
      this.isBusy = true;
      testimonial
              .update(this.testimonial.id, fd)
              .then(response => {
                this.isBusy = false;
                this.$snotify.success("Updated successfully");
                this.$tabs.close();
                this.$tabs.open({name: "testimonial"});
              })
              .catch(() => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
              });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.testimonial) {
        if (
                key == "imageFile" &&
                this.testimonial["imageFile"] &&
                this.testimonial["imageFile"] != null
        ) {
          if (typeof this.testimonial["imageFile"] == "object") {
            fd.append("imageFile", this.testimonial[key]);
          }
        } else if (key == "is_active") {
          fd.append("is_active", this.testimonial.is_active ? 1 : 0);
        }  else {
          if (key != "id" && this.testimonial[key]) {
            fd.append(key, this.testimonial[key]);
          }
        }
      }
      return fd;
    }
  }
};
</script>
